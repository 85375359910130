body {
  margin: 0; 
}

@font-face {
  font-family: "Larken";
  src:  url("fonts/Larken-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Larken";
  src: url("fonts/Larken-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Larken";
  src: url("fonts/Larken-ExtraBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Larken";
  src: url("fonts/Larken-ExtraBoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
 }

 @font-face {
  font-family: 'Ampersand';
  src: url("fonts/ACaslonPro-SemiboldItalic.otf") format("opentype");
  unicode-range: U+0026, U+2018-2019, U+201C-201D;
} 


/* @font-face {
  font-family: 'Quotes';
  src: local('Georgia');
  unicode-range: U+2018-2019, U+201C-201D;
}  */

@font-face {
  font-family: 'Brackets';
  src: local('Arial');
  unicode-range: U+007B-007D;
} 